import React from "react";
import classes from "./AboutPage.module.css";

function AboutPage() {
  return (
    <div className={classes.itemcontentcomponent}>
      Hic qui consequatur quae ea illo. Eum ex et ut exercitationem voluptates
      vero sed voluptatem. Ducimus debitis ex rem earum. Sunt consequatur velit
      nobis exercitationem. Laborum praesentium vero deserunt id qui accusantium
      assumenda non. Quis error numquam voluptatibus aut praesentium dolor sit
      ex. Dolor ut adipisci maiores culpa. Tenetur molestias consequatur
      distinctio. Optio quaerat quod totam quibusdam reprehenderit facilis. Ut
      sunt enim occaecati magni corporis. Minus soluta corporis quia quod natus
      omnis. Est veniam quod cumque voluptates nihil magni. Officia sed tenetur
      maiores corrupti impedit necessitatibus aliquid voluptatum. At voluptatem
      culpa iure. Est nihil et nemo perspiciatis minima. Voluptatum vero sed
      qui. Velit excepturi nihil esse. Porro enim eligendi dolorem eius. Est
      earum et et. Saepe est et nobis fuga autem. Voluptatem esse ea neque ea
      minima quo quam. Magnam velit at cum nobis et magni quasi aut. Qui a magni
      provident nemo et exercitationem ut sed. Alias a natus nihil dicta sed.
    </div>
  );
}

export default AboutPage;
